exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-artikel-index-js": () => import("./../../../src/pages/artikel/index.js" /* webpackChunkName: "component---src-pages-artikel-index-js" */),
  "component---src-pages-email-confirmation-js": () => import("./../../../src/pages/email_confirmation.js" /* webpackChunkName: "component---src-pages-email-confirmation-js" */),
  "component---src-pages-fleischverkauf-index-js": () => import("./../../../src/pages/fleischverkauf/index.js" /* webpackChunkName: "component---src-pages-fleischverkauf-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-index-js": () => import("./../../../src/pages/kontakt/index.js" /* webpackChunkName: "component---src-pages-kontakt-index-js" */),
  "component---src-pages-mail-js": () => import("./../../../src/pages/mail.js" /* webpackChunkName: "component---src-pages-mail-js" */),
  "component---src-pages-team-index-js": () => import("./../../../src/pages/team/index.js" /* webpackChunkName: "component---src-pages-team-index-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */),
  "component---src-templates-directus-artikel-js": () => import("./../../../src/templates/directus_artikel.js" /* webpackChunkName: "component---src-templates-directus-artikel-js" */),
  "component---src-templates-team-js": () => import("./../../../src/templates/team.js" /* webpackChunkName: "component---src-templates-team-js" */)
}

